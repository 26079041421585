import React from 'react';

const CFScheckbox = (props) => {

    const {onChange=""}=props;
    return (
        <div className="checkbox-wrapper">
             <input id="noGroup" type="checkbox" className="checkbox-content cursor-pointer" onChange={onChange}/>
             <label className="cursor-pointer" for="noGroup">No Groups Assigned</label>   
        </div>
    )
}

export default CFScheckbox;

// export const BASE_URL = "http://localhost:8000";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AUTH = BASE_URL + "/auth";
export const USERS = BASE_URL + "/users";
export const LOGIN = USERS + "/login";
export const LOGOUT = USERS + "/logout";
export const ROLE = BASE_URL + "/role";
export const ALL_ROLES = ROLE + "/allRoles";
export const USER_ROLE = BASE_URL + "/userRole";
export const URL_CONFIGURATION = BASE_URL + "/link";
export const AUDIT_URL = URL_CONFIGURATION + "/linkType";
export const REPORT_URL = URL_CONFIGURATION + "/linkType";
export const UPLOAD_USERS = BASE_URL + "/users/uploadUsers";
export const ACTION_LOG = BASE_URL + "/actionlog";

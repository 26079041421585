import { useDispatch } from "react-redux";
import { LOGIN, LOGOUT } from "../utils/EndPoints";
import makeRequest from "../utils/request";
import { showToast } from "../utils/showToast";

export const getUserLoginData = (key, type, data, token) => {
  let params = {
    ...data,
  };
  return (dispatch) => {
    makeRequest(LOGIN, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => {
        dispatch({
          type: "LOGIN_DETAILS",
          payload: response,
        });
        localStorage.setItem("token", token);
        localStorage.setItem("userType", JSON.stringify(response));
        // console.log(response,"response");
        // to handle after login
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    makeRequest(LOGOUT, {
      method: "POST",
    })
      .then((response) => {
        dispatch({
          type: "LOGOUT",
          payload: response,
        });
      })
      .catch((error) => {
        console.log(error);
        showToast(error.message || "Error", true);
      });
  };
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QTabs from "../../components/BaseUI/QTabs/QTabs";
import QLabel from "../../components/Forms/QLabel";
import QDataTable from "../../components/Tables/QDataTable";
import StarterPage from "../StarterPage";
import {
  deleteUserManagementData,
  getUserManagementData,
  csvFileUpload,
  setUserManagementData,
  editUserManagementData,
  getRoles,
} from "./actions";
import {
  Group_Added_Btn_Label,
  Group_Add_Model_Title,
  Group_Description_Field_Label,
  Group_Description_Field_Placeholder,
  Group_Name_Field_Label,
  Group_Name_Field_Placeholder,
  Group_Update_Model_Title,
  SEARCH_MIN_CHAR,
  User_Add_Model_Title,
  User_Update_Model_Title,
} from "../../utils/Labels";

import "./style.scss";
import { Form } from "react-bootstrap";
import CFSUploadFile from "../../components/CFSUploadFile";
import useDebounce from "../../utils/hooks/useDebounce";

const ManagementPage = () => {
  const defaultPage = { page: 1, sizePerPage: 8 };
  const dispatch = useDispatch();
  const [tableType, setTableType] = useState({ key: "users", type: "USERS" });
  const [searchQeury, setsearchQuery] = useState("");
  const [filterClear, setFilterClear] = useState(false);
  const [noGroup, setNoGroup] = useState(false);
  const [sort, setSort] = useState({ fieldName: "", order: "" });
  const [debouncedText] = useDebounce(searchQeury, 1000);
  const [uploadedFile, setUploadedFile] = useState("");

  //to controll pagination and set number of rows to be visible 💂‍♂️
  const [paginator, setPaginator] = useState(defaultPage);

  useEffect(() => {
    dispatch(
      getUserManagementData({
        key: tableType.key,
        type: tableType.type,
        paginator,
        sort,
        noGroup,
        getAllRoles: true,
      })
    );
  }, []);

  useEffect(() => {
    if (debouncedText.length > SEARCH_MIN_CHAR) {
      dispatch(
        getUserManagementData({
          key: tableType.key,
          type: tableType.type,
          query: debouncedText,
          noGroup,
          paginator: defaultPage,
          sort,
        })
      );
      setFilterClear(false);
    } else if (filterClear && !debouncedText.length) {
      dispatch(
        getUserManagementData({
          key: tableType.key,
          type: tableType.type,
          paginator: defaultPage,
          sort,
          noGroup,
        })
      );
      setPaginator(defaultPage);
      setFilterClear(false);
    } else {
      setFilterClear(false);
    }
  }, [debouncedText]);

  let data = useSelector((state) => {
    return state;
  });
  const getDataBasedOnPageNumber = ({ page, sizePerPage }) => {
    setPaginator({
      page,
      sizePerPage,
    });
    dispatch(
      getUserManagementData({
        key: tableType.key,
        type: tableType.type,
        paginator: { page: page, sizePerPage: sizePerPage },
        sort,
        noGroup,
      })
    );
  };
  const defineRolesHeaders = [
    {
      dataField: "roleName",
      text: Group_Name_Field_Label,
      placeholder: Group_Name_Field_Placeholder,
      validationMsg: "Group is a required field",
      // sort: true,
      headerClasses: "text-font-style role-text",
    },
    {
      dataField: "description",
      text: Group_Description_Field_Label,
      placeholder: Group_Description_Field_Placeholder,
      validationMsg: "Description is a required field",
      headerClasses: "desc-tab text-font-style",
    },
  ];

  const usersHeaders = [
    {
      dataField: "firstName",
      text: "First Name",
      placeholder: "Enter First Name",
      sort: true,
      onSort: (field, order) => {
        setSort({ fieldName: field, order: order });
        dispatch(
          getUserManagementData({
            key: "users",
            type: "USERS",
            paginator,
            sort: { fieldName: field, order: order },
            noGroup,
          })
        );
      },
      validationMsg: "First Name is a required field",
      headerClasses: "text-font-style user-header",
    },
    {
      dataField: "lastName",
      text: "Last Name",
      placeholder: "Enter Last Name",
      validationMsg: "Last Name is a required field",
      headerClasses: "text-font-style user-header",
      sort: true,
      onSort: (field, order) => {
        setSort({ fieldName: field, order: order });
        dispatch(
          getUserManagementData({
            key: "users",
            type: "USERS",
            paginator,
            sort: { fieldName: field, order: order },
            noGroup,
          })
        );
      },
    },
    {
      dataField: "userAdId",
      text: "Email",
      placeholder: "Enter Email",
      validationMsg: "Email is a required field",
      headerClasses: "user-thead text-font-style",
      sort: SVGComponentTransferFunctionElement,
      onSort: (field, order) => {
        setSort({ fieldName: field, order: order });
        dispatch(
          getUserManagementData({
            key: "users",
            type: "USERS",
            paginator,
            sort: { fieldName: field, order: order },
            noGroup,
          })
        );
      },
    },
    {
      dataField: "groupDetails",
      text: "Groups",
      label: "Groups",
      placeholder: "Select group",
      suggestions:
        data.urlManagementReducer.allRoles &&
        data.urlManagementReducer.allRoles.length &&
        data.urlManagementReducer.allRoles.map((item) => ({
          label: item.roleName,
          value: item.id,
        })),
      classes: "table-roles",
      validationMsg: "Groups is a required field",
      headerClasses: "text-font-style table-header-urlrole",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            {(row &&
              row.groupDetails &&
              row.groupDetails.length &&
              row.groupDetails.map((item, index) => {
                return <span className="chips-card">{item.roleName}</span>;
              })) ||
              ""}
          </>
        );
      },
    },
  ];
  const handleAddNewData = (newData) => {
    dispatch(
      setUserManagementData({
        key: tableType.key,
        type: tableType.type,
        data: newData,
        paginator: defaultPage,
        sort,
        noGroup,
      })
    );
  };
  const handelDeleteRow = (data) => {
    dispatch(
      deleteUserManagementData({
        key: tableType.key,
        type: tableType.type,
        data: data,
        paginator,
        sort,
        noGroup,
      })
    );
  };
  const handelBulkDelete = (data) => {};
  const handleEditData = (newData) => {
    dispatch(
      editUserManagementData({
        key: tableType.key,
        type: tableType.type,
        data: newData,
        paginator,
        sort,
        noGroup,
      })
    );
  };
  const handelSearchData = (query) => {
    setsearchQuery(query);
  };

  const handleRemoveData = (data) => {
    // dispatch(getUserManagementData(`${BASE_URL}/${tableType.key}?q=${data}`));
    if (searchQeury) {
      if (searchQeury.length > SEARCH_MIN_CHAR) {
        dispatch(
          getUserManagementData({
            key: tableType.key,
            type: tableType.type,
            paginator: defaultPage,
            sort,
            noGroup,
          })
        );
        setPaginator(defaultPage);
      }
      setsearchQuery("");
    }
  };
  const handleSearchRemove = (data) => {
    if (data.keyCode === 8) {
      setFilterClear(true);
    }
  };
  const handleNoGroupChecked = (checked) => {
    setNoGroup(checked);
    dispatch(
      getUserManagementData({
        key: "users",
        type: "USERS",
        paginator,
        sort,
        noGroup: checked,
      })
    );
  };
  const handleFileUpload = (ref) => {
    const formData = new FormData();
    formData.append("file", uploadedFile);
    dispatch(
      csvFileUpload({
        formData: formData,
        key: "users",
        type: "USERS",
        paginator: defaultPage,
        sort,
        noGroup,
      })
    );
    setUploadedFile("");
    ref.current.value = "";
  };

  const getFileUpload = (e) => {
    if (e.target.files[0]) {
      setUploadedFile(e.target.files[0]);
    }
  };

  let tabData = [
    {
      eventKey: "users",
      title: "Users",
      content: (
        <QDataTable
          btnText={"User"}
          modalEditLabel={User_Update_Model_Title}
          modalAddLabel={User_Add_Model_Title}
          data={data.urlManagementReducer.users.data}
          columnHeaders={usersHeaders}
          handleAddNewData={(newData) => {
            handleAddNewData(newData);
          }}
          handelDeleteRow={(rowId) => {
            handelDeleteRow(rowId);
          }}
          handelBulkDelete={(data) => {
            handelBulkDelete(data);
          }}
          handleEditData={(newData) => {
            handleEditData(newData);
          }}
          handelSearchData={(data) => {
            handelSearchData(data);
          }}
          handleRemoveData={(data) => {
            handleRemoveData(data);
          }}
          isLoaded={data.loginReducer.isLoader}
          striped
          hoverable
          searchQuery={searchQeury}
          handleSearchRemove={handleSearchRemove}
          handleNoGroupChecked={handleNoGroupChecked}
          getDataBasedOnPageNumber={getDataBasedOnPageNumber}
          totalNumberOfRecords={data.urlManagementReducer.users?.totalRecords}
          pageSize={paginator.sizePerPage}
          paginationRequired={true}
          page={paginator.page}
          eventKey="user"
        />
      ),
    },
    {
      eventKey: "defineRoles",
      title: "Groups",
      content: (
        <QDataTable
          modalEditLabel={Group_Update_Model_Title}
          modalAddLabel={Group_Add_Model_Title}
          btnText={Group_Added_Btn_Label}
          data={data.urlManagementReducer.defineRoles.data}
          columnHeaders={defineRolesHeaders}
          handleAddNewData={(newData) => {
            handleAddNewData(newData);
          }}
          handelDeleteRow={(rowId) => {
            handelDeleteRow(rowId);
          }}
          handelBulkDelete={(data) => {
            handelBulkDelete(data);
          }}
          handleEditData={(newData) => {
            handleEditData(newData);
          }}
          handelSearchData={(data) => {
            handelSearchData(data);
          }}
          handleRemoveData={(data) => {
            handleRemoveData(data);
          }}
          isLoaded={data.loginReducer.isLoader}
          striped
          hoverable
          searchQuery={searchQeury}
          handleSearchRemove={handleSearchRemove}
          getDataBasedOnPageNumber={getDataBasedOnPageNumber}
          totalNumberOfRecords={
            data.urlManagementReducer.defineRoles?.totalRecords
          }
          pageSize={paginator.sizePerPage}
          paginationRequired={true}
          page={paginator.page}
        />
      ),
    },
  ];

  const handelSelectedTab = (key) => {
    let type =
      key === "defineRoles"
        ? "DEFINE_ROLES"
        : key === "assignRoles"
        ? "ASSIGN_ROLES"
        : "USERS";
    setTableType({ key: key, type: type });
    setsearchQuery("");
    setFilterClear(false);
    setPaginator(defaultPage);
    if (type === "USERS") {
      dispatch(
        getUserManagementData({
          key: `${key}`,
          type: type,
          paginator,
          sort,
          noGroup,
          getAllRoles: true,
        })
      );
    } else {
      dispatch(
        getUserManagementData({
          key: `${key}`,
          type: type,
          paginator,
          sort,
          noGroup,
        })
      );
    }
  };

  return (
    <div
      className={`management-wrapper ${
        tableType.key === "users" ? "user-tab" : "group-tab"
      } `}
    >
      <div className="title-container">
        <QLabel label="User Management" qlabelCls="management-label"></QLabel>
      </div>
      <StarterPage>
        <div className="row">
          <div className="col-12 py-2 user-managment-container">
            {tableType.key === "users" && (
              <CFSUploadFile
                getFileUpload={getFileUpload}
                uploadedFile={uploadedFile}
                ID="fileSelect"
                accept=".csv"
                handleFileUpload={handleFileUpload}
              />
            )}
            <QTabs
              striped
              hoverable
              data={tabData}
              handelSelectedTab={(key) => handelSelectedTab(key)}
            />
          </div>
        </div>
      </StarterPage>
    </div>
  );
};

export default ManagementPage;

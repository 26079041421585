import {
  ALL_ROLES,
  ROLE,
  USERS,
  USER_ROLE,
  UPLOAD_USERS,
} from "../../utils/EndPoints";
import makeRequest from "../../utils/request";
import { showToast } from "../../utils/showToast";
import { saveActionLog } from "../../utils/actionlog";
import axios from "axios";

let URL = { defineRoles: ROLE, assignRoles: USER_ROLE, users: USERS };
const userType = JSON.parse(localStorage.getItem("userType"));
export const getUserManagementData = ({
  key,
  type,
  paginator,
  sort,
  noGroup,
  query = "",
  getAllRoles,
}) => {
  return (dispatch) => {
    makeRequest(
      `${URL[key]}?q=${query}&page=${paginator.page}&numberOfRecords=${
        paginator.sizePerPage
      }${
        key === "users" && sort.fieldName
          ? `&fieldName=${sort.fieldName}&order=${sort.order}`
          : ""
      }${key === "users" ? `&nogroups=${noGroup}` : ""}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        dispatch({
          type: type,
          payload: res,
        });
        if (getAllRoles) {
          dispatch(getRoles());
        }
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};
export const csvFileUpload = async ({
  formData,
  key,
  type,
  paginator,
  sort,
  noGroup,
}) => {
  //   const accessToken = await JSON.parse(localStorage.getItem('token'));

  return (dispatch) => {
    axios
      .post(UPLOAD_USERS, formData, {
        headers: {
          // "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        showToast(`You have successfully Uploaded File`);
        dispatch(
          getUserManagementData({ key, type, paginator, sort, noGroup })
        );
      })
      .catch((error) => {
        showToast(error?.response?.data?.message || "Error", true);
      });
  };
};

export const getRoles = () => {
  return (dispatch) => {
    makeRequest(ALL_ROLES, {
      method: "GET",
    })
      .then((res) => {
        dispatch({
          type: "ALL_ROLES",
          payload: res,
        });
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

export const setUserManagementData = ({
  key,
  type,
  data,
  paginator,
  sort,
  noGroup,
}) => {
  let params = {
    ...data,
  };
  params["createdById"] =
    userType && userType.userDetails && userType.userDetails.id;
  params["lastUpdatedById"] =
    userType && userType.userDetails && userType.userDetails.id;

  return (dispatch) => {
    makeRequest(URL[key], {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => {
        let msg = "Group";
        let desc = params.description
          ? params.description
          : `${params.firstName} ${params.lastName}`;
        if (key === "users") {
          msg = "User";
        }
        showToast(`${msg} added`);
        dispatch(
          getUserManagementData({ key, type, paginator, sort, noGroup })
        );
        dispatch(saveActionLog(`Added ${msg}: ${desc}`));
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

export const deleteUserManagementData = ({
  key,
  type,
  data,
  paginator,
  sort,
  noGroup,
}) => {
  return (dispatch) => {
    makeRequest(`${URL[key]}/${data.id}`, {
      method: "DELETE",
    })
      .then((response) => {
        let msg = "Group";
        let desc = data.description
          ? data.description
          : `${data.firstName} ${data.lastName}`;
        if (key === "users") {
          msg = "User";
        }
        showToast(`${msg} deleted`);
        dispatch(
          getUserManagementData({ key, type, paginator, sort, noGroup })
        );
        dispatch(saveActionLog(`Deleted ${msg}: ${desc}`));
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

export const editUserManagementData = ({
  key,
  type,
  data,
  paginator,
  sort,
  noGroup,
}) => {
  let params = {
    ...data,
  };
  params["lastUpdatedById"] =
    userType && userType.userDetails && userType.userDetails.id;
  return (dispatch) => {
    makeRequest(`${URL[key]}/${data.id}`, {
      method: "PUT",
      body: JSON.stringify(params),
    })
      .then((response) => {
        let msg = "Group";
        let desc = params.description
          ? params.description
          : `${params.firstName} ${params.lastName}`;
        if (key === "users") {
          msg = "User";
        }
        showToast(`${msg} updated`);

        dispatch(
          getUserManagementData({ key, type, paginator, sort, noGroup })
        );
        dispatch(saveActionLog(`Edited ${msg}: ${desc}`));
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

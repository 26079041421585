import { useState, useEffect } from 'react';
import QButton from "../../components/BaseUI/QButton";
import QLabel from "../../components/Forms/QLabel";
// import { Logo } from "../../components/Svgs";
import {ReactComponent as BackgroundLogo} from './backgroundImage.svg';

import "./style.scss";
// import { loginRequest } from "../../authConfig";
import { BASE_URL } from "../../utils/EndPoints";

const LandingPage = () => {
  const [version, setVersion] = useState("v0.00");

  useEffect(() => {    
    setVersion(process.env.REACT_APP_VERSION)
    return () => {
      setVersion("v0.00")
    }
  }, [])
 
  const handleCLick = () => {
    window.open(`${BASE_URL}/sso`, "_self")
  }

  return (
    <div className="landing-page d-flex flex-column justify-content-between h-100 w-100"> 
     <div className="header"><img className="login-logo" src="images/AuditAngelLoginLogo.png" alt="" /></div>
     <div className="landing-body">
         <div className="login-content">
            <QLabel qlabelCls="heading" label={<span>Welcome to the Audit Angel <i>Plus!</i></span>}/>
            <QLabel qlabelCls="subtext" label="Click “Login” to securely access your Audit Tools, Reports and Guides."/>
            <QButton qbtnCls={`btn login-btn`} label="Login" onClick={handleCLick} />
            {/* <a href={`${BASE_URL}/loginn`}>
              <QButton qbtnCls={`btn login-btn`} label="Login" />
            </a> */}
         </div>   
         <div className="login-bg">
           <BackgroundLogo className="login-bg-image"/>
             {/* <img className="login-bg-image" src="images/login_bg.png" alt="" /> */}
             <QLabel qlabelCls="login-bg-text" label="Measurement. Insight. Direction."/>
         </div>
     </div>
     <div className="landing-version">
           {version}
         </div> 
     <div className="landing-footer"/>
    </div>
  );
};

export default LandingPage;

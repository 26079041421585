const initial_state = {
  defineRoles: [],
  assignRoles: [],
  users: [],
  allUsers: [],
  allRoles: [],
};

const urlManagementReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "DEFINE_ROLES":
      return Object.assign({}, state, {
        defineRoles: action.payload,
      });
    case "ASSIGN_ROLES":
      return Object.assign({}, state, {
        assignRoles: action.payload,
      });
    case "USERS":
      return Object.assign({}, state, {
        users: action.payload,
      });
    case "ALL_ROLES":
      return Object.assign({}, state, {
        allRoles: action.payload,
      });
    default:
      return state;
  }
};
export default urlManagementReducer;

import { combineReducers } from "redux";
import urlConfigurationReducer from "./pages/ConfigurationPage/reducer";
import urlManagementReducer from "./pages/ManagementPage/reducer";
import auditDataReducer from "./pages/AuditSelectorPage/reducer";
import reportsDataReducer from "./pages/ReportsAndInsight/reducer";
import actionLogReducer from "./pages/ActionLogPage/reducer";
import loginReducer from "./utils/loginReducer";
import tabReducer from "./utils/tabReducer";

const rootReducer = combineReducers({
  loginReducer,
  urlConfigurationReducer,
  urlManagementReducer,
  auditDataReducer,
  reportsDataReducer,
  actionLogReducer,
  tabReducer,
});

export default rootReducer;

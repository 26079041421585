import React from 'react';
import './style.scss';

const StarterPage = (props) => {

    const { pageTitle = "", starterCls="" } = props

    return (
        <div className="starter-page">
            <div className="d-flex p-2 m-0  starter-container ">
                <div className="col-12">
                    <div className="page-title-box">
                        <p className={`mt-2 page-title ${starterCls}`}>{pageTitle}</p>
                    </div>
                </div>
            </div>
            <div className="d-flex p-2 m-0 page-wrapper">
                <div className="col-12 starter-page-content " >
                    <div className="page-content">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarterPage;
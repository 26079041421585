const initial_state = {
    reportsData: [],
    page:1
  };
  
  const reportsDataReducer = (state = initial_state, action) => {
    switch (action.type) {
      case "REPORTS_DATA":
        return Object.assign({}, state, {
          reportsData:action.page==1? action.payload: [...state.reportsData, ...action.payload],
          total_records:action.total_records,
          page:action.page
        });
        case "REPORTS_DATA_SEARCH":
          return{
            ...state,
            reportsData:action.payload,
            total_records:action.total_records,
            page:action.page
          }
      default:
        return state;
    }
  };
  export default reportsDataReducer;
  
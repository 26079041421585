import { useState, Suspense, lazy } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import QButton from '../../BaseUI/QButton';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';
import '../../../App.scss';
import QTextInput from '../../Forms/QTextInput';
import { useEffect } from 'react';
import NoDataComp from '../../NoDataComp';
import { EditIcon, DeleteIcon } from '../../Svgs';
import CFScheckbox from '../../CFScheckbox';
import { Search_placeholder,No_Data_Msg, Delete_Pop_up_Msg } from '../../../utils/Labels';
import { LeftIcon, RightIcon } from '../../Svgs';
const AddEditModal = lazy(() => import('./AddEditModal'));

const ExportCSVBtn = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return <QButton qbtnCls={`btn add-btn `} label={`Export CSV`} onClick={ handleClick } />
};


const QDataTable = (props) => {

    let { data = [], eventKey="", columnHeaders = [], paginationRequired = false, keyField = 'id', bordered = false, handleAddNewData, handelDeleteRow, handleEditData, handelSearchData, handelBulkDelete, handleRemoveData, handleNoGroupChecked, searchQuery = "", btnText = "", page = 1, pageSize = 5, isLoaded = false, handleSearchRemove ,  ModalHeader, showAdd = true, showActions= true, showSearch=true, showExportCSV=false, csvColumns ={} } = props;
    
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [newRowData, setNewRowData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [deleteRow, setDeleteRow] = useState({});
    const [selectedData, setSelectedData] = useState([]);
    const [items, setItems] = useState([]);
    const [currentPagination, setCurrentPagination] = useState({ page: page, sizePerPage: pageSize });
    const [isValidated, setIsValidated] = useState(false);
    const [isLinkValid, setIsLinkValid] = useState(true);
    const columns = showActions ? ([...columnHeaders, {
        dataField: "actions",
        text: "Actions",
        headerClasses: "text-font-style url-tab-action",
        csvExport: false,
        formatter: (cell, row, rowIndex) => {
            return (<>
                <span className="mr-4"><EditIcon className="cursor-pointer" onClick={(event) => { handleEdit(row, rowIndex) }} /></span>
                <span><DeleteIcon className="cursor-pointer" onClick={(event) => { handleDelete(row, rowIndex) }} /></span>
            </>
            );
        }
    }
    ]) : columnHeaders;
    //controlled paginator to reset page number while searching
    useEffect(() => {
        setItems(data)
        setCurrentPagination({ page, sizePerPage: pageSize })
        // console.log(data)
        return () => {
            setIsEdit(false);
        }
    }, [props.data, page])

    const handleEdit = (row, rowIndex) => {
        setNewRowData(row);
        setIsEdit(true);
        handleShow();
    }
    const handleDelete = (row, rowIndex) => {
        setShowDelete(true);
        setDeleteRow(row);

    }
    const handleClose = () => {
        setShow(false);
        setIsValidated(false);
    }
    const onDeleteHide = () => {
        setShowDelete(false);
        setDeleteRow({});

    }
    const onConfirm = () => {
        setShowDelete(false);
        if (deleteRow) {
            handelDeleteRow(deleteRow);
            setDeleteRow({});
        } else {
            if (selectedData.length) {
                setSelectedData([]);
            }
        }
    }
    const handleShow = () => {
        setShow(true);
        setIsValidated(false);
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {

        },
    };

    const handleAddData = () => {
        setIsEdit(false);
        setNewRowData({});
        handleShow();
    }
    const handleSubmit = (event) => {
        let isvalid = true
        for (let i = 0; i < columnHeaders.length; i++) {
            if (!newRowData[(columnHeaders[i].dataField === "roleDetails") ? "roles" : (columnHeaders[i].dataField === "groupDetails") ? "groups" : columnHeaders[i].dataField]) {
                isvalid = false;
                event.preventDefault();
                event.stopPropagation();
                setIsValidated(true)
                break;
            }

            if (columnHeaders[i].dataField === "link" ) {
              if (!newRowData[(columnHeaders[i].dataField)].toLowerCase().match(process.env.REACT_APP_LIMIT_URL)  ) {
                isvalid = false;
                event.preventDefault();
                event.stopPropagation();
                setIsLinkValid(false)
                setIsValidated(true)
                break;
              } else {
                setIsLinkValid(true)
                setIsValidated(true)
                break;
              }
            }
        }
       
        if (isvalid) {
            event.preventDefault();
            setIsValidated(false)
            if (isEdit) {
                handleEditData(newRowData)

            } else {
                handleAddNewData(newRowData)
            }
            handleClose();

        }
    }
    const handleInputChange = (event) => {
        if(event.target.name==="userAdId"){
            setNewRowData({ ...newRowData, [`${event.target.name}`]: (event.target.value).toLowerCase()});
        }else{
            setNewRowData({ ...newRowData, [`${event.target.name}`]: event.target.name === "id" ? parseInt(event.target.value) : event.target.value });
        }
    }
    const handleSelectChange = (data) => {
        setNewRowData({ ...newRowData, [data.name]: data.value });
    }
    const handleMultiSelectChange = (data,field) => {
        setNewRowData({ ...newRowData, [(field==="roleDetails")?`roles`:`groups`]: data });
    }

    const onTableChange = (type, newState) => {
        // handle any data change here
    }

    const handlePageChange = (page, sizePerPage) => {
        props?.getDataBasedOnPageNumber?.({ page: page, sizePerPage: sizePerPage })
    }

    const handleSearch = (evt) => {
        handelSearchData(evt.target.value)
    }

    const handleRemove = (evt) => {
        if (evt.target) {
            handleRemoveData(evt.target.value)
        }
    }
    const handleNoGroup = (evt) => {
        handleNoGroupChecked(evt.target.checked)
    }


    return (
      <div
        id='qdata-table-wrapper'
        style={{
          overflow: "auto",
        }}
      >
        <div className='table-container'>
          {eventKey ? <CFScheckbox onChange={handleNoGroup} /> : ""}
          <div className='table-wrapper col-sm-6 col-md-4 p-0'>
            {!showSearch || (searchQuery && searchQuery.length) ? (
              ""
            ) : (
              <div className='search-menu-table-icon'>
                <img
                  src='/images/Searchicon.svg'
                  height='15px'
                  width='15px'
                  alt=''
                />
              </div>
            )}
            {showSearch && (<QTextInput
              className='search-bar table-search fa fa-times'
              type='text'
              name='search'
              placeholder={Search_placeholder}
              onInputChange={handleSearch}
              value={searchQuery}
              handleSearchRemove={handleSearchRemove}
            />)}
            {showSearch && searchQuery && searchQuery.length > 0 && (
              <span className='close-icon table-close-icon'>
                <img
                  src='images/close.svg'
                  style={{
                    marginLeft: "-29px",
                    zIndex: "100",
                    height: "15px",
                    width: "15px",
                  }}
                  onClick={handleRemove}
                  alt=''
                />
              </span>
            )}
          </div>
            { showAdd && (
              <div className='url-tab-view'>
                <QButton
                  qbtnCls={`btn add-btn `}
                  label={`+  ${btnText}`}
                  onClick={() => handleAddData()}
                />
            </div>)}
            { showExportCSV && (
              <div className='url-tab-view'>
                <ExportCSVBtn />
            </div>)}            
        </div>
        {items && items.length > 0 ? (
          // <ToolkitProvider     
          // keyField={keyField}
          // data={ items }
          // columns={ csvColumns }
          // exportCSV>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: currentPagination.page,
              sizePerPage: currentPagination.sizePerPage,
              totalSize: props?.totalNumberOfRecords,
              alwaysShowAllBtns: true,
              pageButtonRenderer: ({
                page,
                active,
                disable,
                title,
                onPageChange,
              }) => {
                const handleClick = (e) => {
                  e.preventDefault();
                  onPageChange(page);
                };
                const activeStyle = {};
                if (active) {
                  activeStyle.color = "#fff";
                } else {
                  activeStyle.color = "black";
                }
                if (typeof page === "string") {
                  activeStyle.backgroundColor = "white";
                  activeStyle.color = "black";
                }
                return (
                  <li
                    className={`page-item ${
                      active ? "active-page-list" : ""
                    } page-list ${
                      title.includes("page") ? "pagination-btn" : ""
                    } `}
                  >
                    <a href='#' onClick={handleClick} style={activeStyle}>
                      {page}
                    </a>
                  </li>
                );
              },
              firstPageText: (
                <span className='pagination-content'>
                  <LeftIcon/><LeftIcon/>
                  {/* <img src='images/firstpage.png' alt='<<' width="13px" height="10px" /> */}
                </span>
              ),
              prePageText: (
                <span className='pagination-content'>
                  <LeftIcon/>
                </span>
              ),
              nextPageText: (
                <span className='pagination-content'>
                  <RightIcon/>
                </span>
              ),
              lastPageText: (
                <span className='pagination-content'>
                  <RightIcon/><RightIcon/>
                </span>
              ),
              onPageChange: handlePageChange,
            })}
          >
            {(props) => (
              <div id='bootstrap-tab'>
                <BootstrapTable
                  remote={paginationRequired}
                  bootstrap4
                  keyField={keyField}
                  wrapperClasses='table-responsive'
                  data={items}
                  columns={columns}
                  striped
                  hover
                  bordered={bordered}
                  noDataIndication='No Data'
                  rowEvents={rowEvents}
                  onTableChange={onTableChange}
                  rowClasses={"rowClasses "}
                  headerWrapperClasses={"headerwrapper"}
                  {...props.paginationTableProps}
                />
                <div className='pagination-box'>
                  <PaginationListStandalone {...props.paginationProps} />
                </div>
              </div>
            )}
          </PaginationProvider>
          // </ToolkitProvider>
        ) : isLoaded ? (
          ""
        ) : (
          <>
            <div className='search-box'></div>
            <div>
              <NoDataComp heading={No_Data_Msg} />
            </div>
          </>
        )}
        <Suspense fallback={<div>Loading...</div>}>
        <AddEditModal 
        show={show} 
        modalEditLabel={props.modalAddLabel}
        modalAddLabel={props.modalAddLabel}
        handleClose={handleClose}
        isEdit={isEdit}
        isValidated={isValidated}
        isLinkValid={isLinkValid}
        handleSubmit={handleSubmit}
        columns={columns}
        handleInputChange={handleInputChange}
        newRowData={newRowData}
        handleSelectChange={handleSelectChange}
        handleMultiSelectChange={handleMultiSelectChange}/>
      </Suspense>
        
        <Modal
          show={showDelete}
          onHide={() => onDeleteHide(false)}
          backdrop='static'
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className='delete-msg-body'>
            <div className='delete-msg-content'>
                {Delete_Pop_up_Msg}
            </div>
            <div className='delete-action-btn'>
              <QButton
                onClick={() => onDeleteHide(false)}
                qbtnCls='btn mr-4 cancel-btn'
                label='Cancel'
              />
              <QButton
                onClick={() => onConfirm(false)}
                qbtnCls='btn submit-btn'
                label='Submit'
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
};
export default QDataTable;
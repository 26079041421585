const initial_state = {
  openTabs: {},
};

const tabReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "NEW_TAB":
      return {
        openTabs: {
          ...state.openTabs,
          [action.payload.name]: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};
export default tabReducer;

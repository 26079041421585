import React from 'react'
import { Link } from 'react-router-dom'

const NoDataComp = (props) => {

    const {label="",heading=""}=props; 
    return (
        <div className="nodata-img">
          <div className="img-content">
            <img src="images/nodata.svg" height="150px" width="150px" alt=""/>
          </div>
          <div className="nodata-content">
               <h3>{heading}</h3> 
               {/* <Link to="" style={{color:"#BDBDBD"}}>{label}</Link> */}
          </div>
        </div>
    )
}

export default NoDataComp;

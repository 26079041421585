const initial_state = {
  isLoader: false,
  userRoleDetails: {},
};

const loginReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "API_REQUEST_INITIATED":
      return {
        ...state,
        isLoader: true,
      };

    case "API_REQUEST_COMPLETED":
      return {
        ...state,
        isLoader: false,
      };
    case "LOGIN_DETAILS":
      return {
        ...state,
        userRoleDetails: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        userRoleDetails: action.payload,
      };

    default:
      return state;
  }
};
export default loginReducer;

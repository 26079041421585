const initial_state = {
  auditData: [],
  page:1
};

const auditDataReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "AUDIT_DATA":
      return Object.assign({}, state, {
        auditData:action.page==1? action.payload:[...state.auditData, ...action.payload],
        total_records:action.total_records,
        page:action.page
      });
      case "AUDIT_DATA_SEARCH":
        return{
          ...state,
          auditData:action.payload,
          total_records:action.total_records,
          page:action.page
        }
    default:
      return state;
  }
};
export default auditDataReducer;

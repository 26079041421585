import React, { useState } from 'react';
import './MainHeader.scss'
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import ConfirmationModal from "../../BaseUI/ConfirmationModal";
import { LABEL_LOGOUT, LOG_OUT_CONFIRMATION_TEXT } from '../../../utils/Labels';
import handleLogout from '../../../utils/handleLogout';
import _history from '../../../utils/history';
// import { useMsal } from "@azure/msal-react";

import { ReactComponent as Avatar } from '../../../assets/images/avatar.svg';

const { REACT_APP_TEST_HEADER } = process.env;


const MainHeader = ({ toggleSidebar, user, setIsAuthorized }) => {
    // const { instance } = useMsal();

    const [showConfirmation, setConfirmation] = useState(false);


    const onHide = () => {
        setConfirmation(false);
    };

    const onConfirm = () => {
        setConfirmation(false);
        localStorage.clear();
        _history.push("/");
    };


    return (<React.Fragment>
        {showConfirmation && <ConfirmationModal body={LOG_OUT_CONFIRMATION_TEXT} title={LABEL_LOGOUT} show={showConfirmation} onHide={onHide} onConfirm={onConfirm} />}

        <header className="main-header">
            <Navbar>
                { REACT_APP_TEST_HEADER && (<h3><span className='badge bg-info text-dark'>{REACT_APP_TEST_HEADER}</span></h3>)}
                <Navbar.Toggle fixed="basic-navbar-nav " />
                <Navbar.Collapse id="basic-navbar-nav" fixed="top">
                    <Nav.Link href="#home"><i onClick={toggleSidebar} className={"toggle-sidebar"} >
                        <img src="images/hamburger.svg" alt="" /></i></Nav.Link>
                    <Nav className="me-auto core-header d-flex align-items-center">
                        <NavDropdown title={<span className="user-label"><Avatar className=" rounded-circle mr-2" />
                            <span>{user}</span>
                        </span>} id="basic-nav-dropdown">
                            <NavDropdown.Item className="logout-menu" onClick={()=>handleLogout()}><span>Logout</span></NavDropdown.Item>
                            {/* <NavDropdown.Item className="logout-menu" onClick={()=> _history.push("/logout")}><span>Logout</span></NavDropdown.Item> */}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    </React.Fragment>
    );
}

export default MainHeader;
import { useState } from "react";
import QAccordion from "../../components/BaseUI/QAccordion/QAccordion";
import QTextInput from "../../components/Forms/QTextInput";
import faqData from "./faq.json";
import QLabel from "../../components/Forms/QLabel"
import "./style.scss";
import { Search_placeholder } from "../../utils/Labels";

const HelpSupportPage = (props) => {
  const { searchQuery = "", ...otherprops } = props;
  const [searchData, setSearchData] = useState("");
  const [data, setdata] = useState(faqData);
  let searchedData = [];
  const handleSearch = (evt) => {
    // console.log(evt.target.value)
    setSearchData(evt.target.value);
    if (evt.target.value.length > 0) {
      searchedData = data.filter((item, index) => {
        if (
          item.question.toUpperCase().includes(evt.target.value.toUpperCase())
        ) {
          return true;
        }
      });
      setdata(searchedData);
    } else {
      setdata(faqData);
    }
  };
  const handleRemove = () => {
    setSearchData("");
    setdata(faqData);
  };

  return (
    <div className="help-support">
      <div className="help-support-container">
        <div className="help-support-wrapper"> 
        <QLabel label={"Help & Support"}
              qlabelCls="help-support-label"/>
        </div>
        <div className="search-wrapper col-sm-6  col-md-4">
        {searchData && searchData.length ? "" :<div className="search-menu-help-icon"><img src="/images/Searchicon.svg" height="15px" width="15px" alt=""/></div>}
          <QTextInput
            className="search-bar help-search-input  fa fa-times"
            type="text"
            name="search"
            placeholder={Search_placeholder}
            onInputChange={handleSearch}
            value={searchData}
          />
          {searchData && searchData.length > 0 && (
            <span className="help-support-close-icon">
              <img
                src="images/close.svg"
                style={{
                  marginLeft: "-29px",
                  zIndex: "100",
                  height: "15px",
                  width: "15px",
                }}
                onClick={handleRemove}
                alt=""
              />
            </span>
          )}
        </div>
      </div>
        <QAccordion data={data} defaultActiveKey={-1} />
    </div>
  );
};
export default HelpSupportPage;

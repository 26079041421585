import { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { UrlIcon, UserIcon, LogIcon, AuditIcon, ReportIcon, HelpSupportIcon } from "../../Svgs";
import "./MainSidebar.scss";
import { HEADER_REPORTS_INSIGHT, HEADER_HELP_AND_SUPPORT } from "../../../utils/Labels";

const MainSidebar = ({ isCollapsed, toggleSidebar, role }) => {
  let path = window.location.pathname;
  let id = (path === "/url-configuration") ? 1 : (path === "/user-management") ? 2 : (path === "/audit-selector") ? 3 : (path === "/reports-insight") ? 4 : (path === "/help-support") ? 5 : (path === "/actionlog") ? 6 : 1;
  const [activeID, setActiveID] = useState(id);
  const [version, setVersion] = useState("v0.00");

  useEffect(() => {
    setVersion(process.env.REACT_APP_VERSION)
    setActiveID(id);
  }, [id])
  const handelClick = (id) => {
    setActiveID(id);
    toggleSidebar()
  };

  return (
    <ProSidebar
      breakPoint={`sm md  ${!isCollapsed ? "hide-sidebar" : "toggled"}`}
      onToggle={toggleSidebar}
      {...(isCollapsed && { collapsed: true })}
    >
      <Menu iconShape="square">
        <Navbar.Brand href="#" className="logo-container">
          {<img src="images/AuditAngelLogo.png" height="50px" width="150px" alt=""/>}
        </Navbar.Brand>
        {/* {
          (console.log("role"),
            console.log(role))
        } */}

        {role && role.isCustomer ?
          <>
            <MenuItem
              className={(3 === activeID ? "active" : "")}
              onClick={() => handelClick(3)}
              show={role.isCustomer}
            >
              <Link to="/audit-selector">
                <AuditIcon className="svg-icon" />Audit Selector</Link>
            </MenuItem>
            <MenuItem
              className={(4 === activeID ? "active" : "")}
              onClick={() => handelClick(4)}>
              <Link to="/reports-insight">
                <ReportIcon className="svg-icon" />{HEADER_REPORTS_INSIGHT}</Link>
            </MenuItem>
            <MenuItem
              className={(5 === activeID ? "active" : "")}
              onClick={() => handelClick(5)}>
              <Link to="/help-support">
                <HelpSupportIcon className="svg-icon" />{HEADER_HELP_AND_SUPPORT}</Link>
            </MenuItem>
          </> : ""}
        {role && role.isAdmin ?
          <Menu>
            <MenuItem className="url-config-admin">Admin</MenuItem>
            <MenuItem key={1}
              className={(1 === activeID ? "active" : "")}
              onClick={() => handelClick(1)}
            >
              <Link to="/url-configuration">
                <UrlIcon className="svg-icon" />
                URL configuration</Link>
            </MenuItem>
            <MenuItem key={2}
              className={(2 === activeID ? "active" : "")}
              onClick={() => handelClick(2)}
            >
              <Link to="/user-management">
                <UserIcon className="svg-icon" />User Management</Link>
            </MenuItem>
            <MenuItem key={6}
              className={(6 === activeID ? "active" : "")}
              onClick={() => handelClick(6)}
            >
              <Link to="/actionlog">
                <LogIcon className="svg-icon" />Activity Log</Link>
            </MenuItem>
          </Menu> : ""}

      </Menu>
      <div className="menu-version">
      {version}
      </div>
    </ProSidebar>
  );
};

export default MainSidebar;

import store from "../store";
// import { InteractionRequiredAuthError, AuthenticationScheme } from '@azure/msal-browser';
// import { useMsal, useAccount } from "@azure/msal-react";
// import { protectedResources } from "../authConfig";
// import { msalInstance } from "../index";

// export const getToken = async (method, url) => {
//   let account = JSON.parse(localStorage.getItem('account'));
//   if (!account) {
//       throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
//   }

//   const tokenRequest = {
//       scopes: [...protectedResources.graphMe.scopes],
//       authenticationScheme: AuthenticationScheme.POP,
//       resourceRequestMethod: method,
//       resourceRequestUri: url,
//   }

//   try {
//       const response = await msalInstance.acquireTokenSilent({
//           account: account,
//           ...tokenRequest
//       });
  
//       return response.accessToken;
//   } catch (error) {
//       if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
//           const response = await this.authService.acquireTokenPopup(tokenRequest);
//           return response.accessToken;
//       }
//   }
// }

function showLoader(visible, loaderId) {
  if (loaderId) {
    if (visible) {
      store.dispatch({
        type: "API_REQUEST_INITIATED",
        loaderId: loaderId,
        initialTheLoader:true
      });
    } else {
      store.dispatch({
        type: "API_REQUEST_COMPLETED",
            loaderId: loaderId,
            initialTheLoader:false
      });
    }
  }
}

async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  // let res= await parseJSON(response) || {};
  const error = response
  throw error;
}

async function parseJSON(response, loaderId) {
  const contentType = response && response.headers && response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    let responseFormatted = await response.json();
    showLoader(false, loaderId);
    return responseFormatted;
  } else {
    let responseFormatted = await response.text();
    showLoader(false, loaderId);
    return responseFormatted;
  }
}
export default async function makeRequest(url, options = {}, isLoaderReq = true) {
  // const accessToken = await JSON.parse(localStorage.getItem('token'));
    options.headers = {
      'accept': 'application/json',
      'Content-Type': 'application/json',
      // "Authorization": `Bearer ${accessToken}`
        }
    let loaderId = null;
    if(isLoaderReq){
      loaderId = Date.now();
      showLoader(true, loaderId);
    }
    try {
      let response = await fetch(url, options);
      if (!response.ok) throw await response.json();
      return parseJSON(response, loaderId);
    } catch (err) {
           if (isLoaderReq) {
          showLoader(false, loaderId);
        }
        throw err
    }
  }



import React from "react";
import "./style.scss";

const CfsFooter = (props) => {
  return (
    <div className="main-footer">
      <div>
        <img src="images/ambulance.svg" width="153px" height="23px" alt="" />
      </div>
      <div>
        <img src="images/AuditAngelLogoSmall.png" width="107px" height="45px" alt="logo" />
      </div>
    </div>
  );
};

export default CfsFooter;

import { Modal } from "react-bootstrap";
import QButton from "../QButton";
import './style.scss';

const ConfirmationModal = ({
  title,
  body,
  show,
  fullscreen,
  onHide,
  onConfirm,
}) => {
  return (
    <Modal
      show={show}
      fullscreen={fullscreen}
      onHide={() => onHide(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="logout-msg-body">
        <span>{body}</span>
        <div className="logout-action-btn">
          <QButton
            onClick={() => onHide(false)}
            qbtnCls="btn mr-4 cancel-btn"
            label="Cancel"
          />
          <QButton
            onClick={() => onConfirm(false)}
            qbtnCls="btn submit-btn"
            label="Confirm"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;

{
  /* <Modal.Footer>
<Button onClick={() => onHide(false)} variant="secondary">Cancel</Button>
<Button onClick={() => onConfirm(false)} variant="primary">Confirm</Button>
</Modal.Footer> */
}

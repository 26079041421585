import QLabel from "../../components/Forms/QLabel";
import CFSList from "../../components/CFSList";
import "./style.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuditSelectorData, getAuditSelectorDataSearch } from "./actions";
import QTextInput from "../../components/Forms/QTextInput";
import { SEARCH_MIN_CHAR, MAX_RECORDS_PER_PAGE, Search_placeholder } from "../../utils/Labels";
import useDebounce from "../../utils/hooks/useDebounce";

export const AuditSelectorPage = (props) => {
  const { searchQuery = "", ...otherprops } = props;
  const dispatch = useDispatch();
  
  const [searchData, setSearchearchData] = useState("");
  const [filterClear, setFilterClear] = useState(false);
  const [debouncedText]=useDebounce(searchData,750)
  
  const [auditData, setAuditData] = useState({
    key: "auditData",
    type: "AUDIT_DATA",
  });

  let data = useSelector((state) => {
    return state.auditDataReducer;
  });


  useEffect(() => {
    if (debouncedText.length > SEARCH_MIN_CHAR) {
      dispatch(getAuditSelectorDataSearch(auditData.type, debouncedText, data.page, MAX_RECORDS_PER_PAGE));
      setFilterClear(false);
    } else if (filterClear && !debouncedText.length) {
      dispatch(getAuditSelectorData(auditData.type, data.page, MAX_RECORDS_PER_PAGE));
      setFilterClear(false);
    } else {
      setFilterClear(false);
    }
  }, [debouncedText])

  useEffect(() => {
    if(JSON.parse(localStorage.getItem("userType"))?.userDetails?.id){
       dispatch(getAuditSelectorData(auditData.type, 1, MAX_RECORDS_PER_PAGE));
    }
  }, [JSON.parse(localStorage.getItem("userType"))?.userDetails?.id]);

  const handleSearch = (data) => {
    setSearchearchData(data);
  };

  const handleRemoveData = () => {
    dispatch(getAuditSelectorData(auditData.type, data.page, MAX_RECORDS_PER_PAGE));
    setSearchearchData("");
  };
  const handleSearchRemove = (data) => {
    if (data.keyCode === 8) {
      setFilterClear(true);
    }
  };

  const handelSearchData = (evt) => {
    let query = evt.target.value;
    setSearchearchData(query);
  };
  const fetchMoreData = () => {
    dispatch(getAuditSelectorData(auditData.type, data.page + 1, MAX_RECORDS_PER_PAGE));
  }
  return (
    <>
      <div className="header-wrapper">
        <div>
          <QLabel label="Audit Selector" qlabelCls="audit-label"></QLabel>
        </div>
        <div className="search-wrapper col-sm-6 col-md-4 p-0">
          {searchData && searchData.length ? "" : <div className="search-menu-icon"><img src="/images/Searchicon.svg" height="15px" width="15px" alt=""/></div>}
          <QTextInput
            className="search-bar audit-search fa fa-times"
            type="text"
            name="search"
            placeholder={Search_placeholder}
            onInputChange={handleSearch}
            handleSearchRemove={handleSearchRemove}
            onInputChange={handelSearchData}
            value={searchData}
          />
          {searchData && searchData.length > 0 && (
            <span className="audit-closeIcon">
              <img
                src="images/close.svg"
                style={{
                  marginLeft: "-29px",
                  zIndex: "100",
                  height: "15px",
                  width: "15px",
                }}
                onClick={handleRemoveData}
                alt=""
              />
            </span>
          )}
        </div>
      </div>
      <div className="d-flex audit-wrapper">
        <div className="col-12 ">

          <CFSList
            qlistCls="cfs-list"
            data={data.auditData}
            page={data.page}
            total_records={data.total_records}
            fetchMore={fetchMoreData}
          ></CFSList>
        </div>
      </div>
    </>
  );
};

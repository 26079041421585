const initial_state = {
  actionLogData: {},
};

const actionLogReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "ACTION_LOG":
      return Object.assign({}, state, {
        actionLogData: action.payload,
      });

    default:
      return state;
  }
};
export default actionLogReducer;

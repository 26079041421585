import React from 'react';
import ReactDOM from 'react-dom';

// import { PublicClientApplication } from "@azure/msal-browser";

// import { msalConfig } from "./authConfig";
import App from './App';
import './hyper-template.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import _history from './utils/history';
import store from './store';
import { Provider } from "react-redux";
import 'react-pro-sidebar/dist/css/styles.css';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
// export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <Provider store={store}>
    <BrowserRouter history={_history}>
      <App 
      // instance={msalInstance}
       />
      {/* <LandingPage></LandingPage> */}
    </BrowserRouter>
    </Provider>

    ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { useEffect, useState } from "react";
import QDataTable from "../../components/Tables/QDataTable";
import {
  deleteUrlConfigData,
  getUrlConfigData,
  setNewUrlConfigData,
  editUrlConfigData
} from "./actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import QLabel from "../../components/Forms/QLabel/QLabel";
import "./style.scss";
import { SEARCH_MIN_CHAR,URL_configuration_Btn_Label,URL_Model_title } from "../../utils/Labels"
import useDebounce from "../../utils/hooks/useDebounce";

const ConfigurationPage = () => {
  const defaultPage = { page: 1, sizePerPage: 10 };
  const dispatch = useDispatch();
  const [searchQuery, setsearchQuery] = useState("");
  const [filterClear, setFilterClear] = useState(false);
  const [paginator, setPaginator] = useState(defaultPage);
  const [sort, setSort] = useState({ fieldName: "", order: "" });
  const [debouncedText] = useDebounce(searchQuery,750)
  let data = useSelector((state) => state)

  useEffect(() => {
    dispatch(getUrlConfigData({paginator,"getAllRoles":true}));
    // dispatch(getRoles());
  }, []);

  useEffect(()=>{
    if (debouncedText.length > SEARCH_MIN_CHAR) {
      dispatch(getUrlConfigData({"query":debouncedText, "paginator":defaultPage,sort}));
      setPaginator(defaultPage)
    }
    else if (filterClear && !debouncedText.length) {
      dispatch(getUrlConfigData({"paginator":defaultPage,sort}));
      setPaginator(defaultPage);
      setFilterClear(false);
    } else {
      setFilterClear(false);
    }
  },[debouncedText])


  const getDataBasedOnPageNumber = ({ page, sizePerPage }) => {
    setPaginator({ page, sizePerPage })
    // get request based on filter query with pagination 🔍
    if (searchQuery.length > SEARCH_MIN_CHAR) {
      return dispatch(getUrlConfigData({"query":searchQuery, "paginator":{ page, sizePerPage },sort}));
    }
    return dispatch(getUrlConfigData({"paginator":{ page, sizePerPage },sort}));
  }
  const columnHeaders = [
    {
      dataField: 'type',
      text: 'Type',
      label: 'Type',
      placeholder:"Select type",
      headerClasses: "text-font-style table-header-urltype",
      validationMsg: "Type is a required field",
      sort: true,
      onSort: (field, order) => {
        setSort({fieldName:field,order:order})
        dispatch(getUrlConfigData({paginator,"sort":{fieldName:field,order:order}}));
      },
      options: [{ label: "Audit", value: "1" }, { label: "Report", value: "2" }],
      formatter: (cell, row, rowIndex) => {
        return <div>{`${row.type === "1" ? "Audit" : "Report"}`}</div>;
      },
    },
    {
      dataField: "description",
      text: "Description",
      placeholder:"Enter description",
      headerClasses: "text-font-style table-header-urldesc",
      validationMsg: "Description is a required field",
      title: (cell, row, rowIndex, colIndex) => `${cell}`,
      classes: 'table-url-desc',
      sort: true,
      onSort: (field, order) => {
        setSort({fieldName:field,order:order})
        dispatch(getUrlConfigData({paginator,"sort":{fieldName:field,order:order}}));
      },
    },
    {
      dataField: "link",
      text: "URL",
      placeholder:"Enter URL",
      headerClasses: "text-font-style table-header-url",
      validationMsg: `URL needs to belong to ${process.env.REACT_APP_LIMIT_URL}`,
      sort: true,
      onSort: (field, order) => {
        setSort({fieldName:field,order:order})
        dispatch(getUrlConfigData({paginator,"sort":{fieldName:field,order:order}}));
      },
    },
    {
      dataField: "roleDetails",
      text: "Groups",
      label: "Groups",
      placeholder:"Select group(s)",
      suggestions: data.urlConfigurationReducer.roles && data.urlConfigurationReducer.roles.length && data.urlConfigurationReducer.roles.map((item) => ({ label: item.roleName, value: item.id })),
      classes: "table-roles",
      validationMsg: "Groups is a required field",
      headerClasses: "text-font-style table-header-urlrole",
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            {row &&
              row.roleDetails &&
              row.roleDetails.length &&
              row.roleDetails.map((item, index) => {
                return <span className="chips-card">{item.roleName}</span>;
              })}
          </>
        );
      },
    },
  ];


  const handleAddNewData = (newData) => {
    dispatch(setNewUrlConfigData({"data":newData, paginator, sort}));
  };
  const handelDeleteRow = (data) => {
    dispatch(deleteUrlConfigData({"data":data, paginator, sort}));
  };
  const handelBulkDelete = (data) => {

  }
  const handleEditData = (newData) => {
    dispatch(editUrlConfigData({"data":newData, paginator, sort}));
  };

  const handelSearchData = (data) => {
    setsearchQuery(data);
  };

  const handleRemoveData = () => {

    if (searchQuery) {
      if (searchQuery.length > SEARCH_MIN_CHAR) {
        dispatch(getUrlConfigData({"paginator":defaultPage}));
        setPaginator(defaultPage);
      }
      setsearchQuery("");
    }
  };

  const handleSearchRemove = (data) => {
    if (data.keyCode === 8) {
      setFilterClear(true)
    }
  }

  return (
    <>
      <div className="d-flex url-config-wrapper">
        <div className="col-12 url-config-container">
          <div>
            <QLabel label="URL Configuration" qlabelCls="label-class"></QLabel>
          </div>
          <QDataTable
            modalEditLabel={URL_Model_title}
            modalAddLabel={URL_Model_title}
            btnText={URL_configuration_Btn_Label}
            ModalHeader="Configure "
            data={data.urlConfigurationReducer.urlConfigData.data}
            isLoaded={data.loginReducer.isLoader}
            columnHeaders={columnHeaders}
            handleAddNewData={(newData) => {
              handleAddNewData(newData);
            }}
            handelDeleteRow={(rowId) => {
              handelDeleteRow(rowId);
            }}
            handelBulkDelete={(data) => {
              handelBulkDelete(data)
            }}
            handleEditData={(newData) => {
              handleEditData(newData);
            }}
            handelSearchData={(data) => {
              handelSearchData(data);
            }}
            page={paginator.page}
            pageSize={paginator.sizePerPage}
            paginationRequired={true}
            handleRemoveData={() => {
              handleRemoveData();
            }}
            handleSearchRemove={(data) => {
              handleSearchRemove(data)
            }}
            getDataBasedOnPageNumber={getDataBasedOnPageNumber}
            striped
            hoverable
            searchQuery={searchQuery}
            totalNumberOfRecords={data.urlConfigurationReducer.urlConfigData.totalRecords}
          />
        </div>
      </div>
    </>
  );
};

export default ConfigurationPage;

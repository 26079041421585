const initial_state = {
  urlConfigData: {},
  roles: [],
};

const urlConfigurationReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "URL_CONFIG":
      return Object.assign({}, state, {
        urlConfigData: action.payload,
      });
    case "ROLES":
      return Object.assign({}, state, {
        roles: action.payload,
      });
    default:
      return state;
  }
};
export default urlConfigurationReducer;

import {useState,useEffect} from 'react'

function useDebounce(text="",time=1000) {
  const [debouncedText, setDebouncedText] = useState(text);

  useEffect(()=>{
    const timeoutId = setTimeout(() =>{
      setDebouncedText(text)
    },time);
    return ()=>{clearTimeout(timeoutId)}
  },[text])

  return [debouncedText]
}

export default useDebounce

import { ACTION_LOG } from "./EndPoints";
import makeRequest from "./request";
import store from "../store";

export const saveActionLog = (action) => {
  let params = {
    action: action,
  };

  const state = store.getState();
  const userId =
    state.loginReducer.userRoleDetails &&
    state.loginReducer.userRoleDetails.userDetails.id;

  if (userId) {
    params["userId"] = userId;

    return (dispatch) => {
      makeRequest(ACTION_LOG, {
        method: "POST",
        body: JSON.stringify(params),
      })
        .then((response) => {
          console.log("action logged");
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    };
  } else {
    return null;
  }
};

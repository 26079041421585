import React from "react";
import { useEffect, useState } from "react";
import QDataTable from "../../components/Tables/QDataTable";
import {
  getActionLogs
} from "./actions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { BASE_URL } from "../../utils/EndPoints";
import QLabel from "../../components/Forms/QLabel/QLabel";
import "./style.scss";
import { SEARCH_MIN_CHAR,URL_configuration_Btn_Label,URL_Model_title } from "../../utils/Labels"
import useDebounce from "../../utils/hooks/useDebounce";
import moment from "moment-timezone";

const ActionLogPage = () => {
  const defaultPage = { page: 1, sizePerPage: 10 };
  const dispatch = useDispatch();
  const [searchQuery, setsearchQuery] = useState("");
  const [filterClear, setFilterClear] = useState(false);
  const [paginator, setPaginator] = useState(defaultPage);
  const [sort, setSort] = useState({ fieldName: "date", order: "DESC" });
  const [debouncedText] = useDebounce(searchQuery,750)
  let data = useSelector((state) => state)

  useEffect(() => {
    dispatch(getActionLogs({paginator:defaultPage, sort}));
    // dispatch(getRoles());
  }, []);


  // useEffect(()=>{
  //   if (debouncedText.length > SEARCH_MIN_CHAR) {
  //     dispatch(getUrlConfigData({"query":debouncedText, "paginator":defaultPage,sort}));
  //     setPaginator(defaultPage)
  //   }
  //   else if (filterClear && !debouncedText.length) {
  //     dispatch(getUrlConfigData({"paginator":defaultPage,sort}));
  //     setPaginator(defaultPage);
  //     setFilterClear(false);
  //   } else {
  //     setFilterClear(false);
  //   }
  // },[debouncedText])


  const getDataBasedOnPageNumber = ({ page, sizePerPage }) => {
    setPaginator({ page, sizePerPage })
    // get request based on filter query with pagination 🔍
    // if (searchQuery.length > SEARCH_MIN_CHAR) {
    //   return dispatch(getActionLogs({"query":searchQuery, "paginator":{ page, sizePerPage },sort}));
    // }
    return dispatch(getActionLogs({"paginator":{ page, sizePerPage },sort}));
  }
  const columnHeaders = [
    {
      dataField: 'date',
      text: 'Date',
      label: 'Date',
      headerClasses: "text-font-style table-header-urltype",
      sort: true,
      isDummyField: true,
      onSort: (field, order) => {
        setSort({fieldName:field,order:order})
        dispatch(getActionLogs({paginator,"sort":{fieldName:field,order:order}}));
      },
      formatter: (cell, row) => {
        const utc = moment.tz(row.date,"Europe/London");
        return utc.clone().tz('Australia/Sydney').format("DD-MM-YYYY h:mm:ss a")
      }
    },
    {
      dataField: 'user',
      text: 'User',
      label: 'User',
      isDummyField: true,
      headerClasses: "text-font-style table-header-urltype",
      sort: false,
      formatter: (cell, row) => {
        return `${row.user.firstName} ${row.user.lastName}`
      }
    },
    {
      dataField: "action",
      text: "Action",
      headerClasses: "text-font-style table-header-urldesc",
      validationMsg: "Action is a required field",
      classes: 'table-url-desc',
      sort: false,
      // onSort: (field, order) => {
      //   setSort({fieldName:field,order:order})
      //   dispatch(getUrlConfigData({paginator,"sort":{fieldName:field,order:order}}));
      // },
    },
    // {
    //   dataField: "link",
    //   text: "URL",
    //   placeholder:"Enter URL",
    //   headerClasses: "text-font-style table-header-url",
    //   validationMsg: "URL is a required field",
    //   sort: true,
    //   onSort: (field, order) => {
    //     setSort({fieldName:field,order:order})
    //     dispatch(getUrlConfigData({paginator,"sort":{fieldName:field,order:order}}));
    //   },
    // },
    // {
    //   dataField: "roleDetails",
    //   text: "Groups",
    //   label: "Groups",
    //   placeholder:"Select group(s)",
    //   suggestions: data.urlConfigurationReducer.roles && data.urlConfigurationReducer.roles.length && data.urlConfigurationReducer.roles.map((item) => ({ label: item.roleName, value: item.id })),
    //   classes: "table-roles",
    //   validationMsg: "Groups is a required field",
    //   headerClasses: "text-font-style table-header-urlrole",
    //   formatter: (cell, row, rowIndex) => {
    //     return (
    //       <>
    //         {row &&
    //           row.roleDetails &&
    //           row.roleDetails.length &&
    //           row.roleDetails.map((item, index) => {
    //             return <span className="chips-card">{item.roleName}</span>;
    //           })}
    //       </>
    //     );
    //   },
    // },
  ];

  const csvColumns = [{
    dataField: 'date',
    text: 'Date'
  }, {
    dataField: 'user.firstName',
    text: 'First Name'
  }, {
    dataField: 'user.lastName',
    text: 'Last Name'
  },{
    dataField: 'action',
    text: 'Action'
  }];

  // const handleAddNewData = (newData) => {
  //   dispatch(setNewUrlConfigData({"data":newData, paginator, sort}));
  // };
  // const handelDeleteRow = (data) => {
  //   dispatch(deleteUrlConfigData({"data":data, paginator, sort}));
  // };
  // const handelBulkDelete = (data) => {

  // }
  // const handleEditData = (newData) => {
  //   dispatch(editUrlConfigData({"data":newData, paginator, sort}));
  // };

  const handelSearchData = (data) => {
    setsearchQuery(data);
  };

  // const handleRemoveData = () => {

  //   if (searchQuery) {
  //     if (searchQuery.length > SEARCH_MIN_CHAR) {
  //       dispatch(getUrlConfigData({"paginator":defaultPage}));
  //       setPaginator(defaultPage);
  //     }
  //     setsearchQuery("");
  //   }
  // };

  // const handleSearchRemove = (data) => {
  //   if (data.keyCode === 8) {
  //     setFilterClear(true)
  //   }
  // }
  return (
    <>
      <div className="d-flex url-config-wrapper">
        <div className="col-12 url-config-container">
          <div>
            <QLabel label="Activity Log" qlabelCls="label-class"></QLabel>
          </div>
          <QDataTable
            modalEditLabel={URL_Model_title}
            modalAddLabel={URL_Model_title}
            btnText={URL_configuration_Btn_Label}
            ModalHeader="Configure "
            data={data.actionLogReducer.actionLogData.data}
            isLoaded={data.loginReducer.isLoader}
            columnHeaders={columnHeaders}
            showAdd={false}
            showActions={false}
            showSearch={false}
            showExportCSV={false}
            csvColumns={csvColumns}
            // handleAddNewData={(newData) => {
            //   handleAddNewData(newData);
            // }}
            // handelDeleteRow={(rowId) => {
            //   handelDeleteRow(rowId);
            // }}
            // handelBulkDelete={(data) => {
            //   handelBulkDelete(data)
            // }}
            // handleEditData={(newData) => {
            //   handleEditData(newData);
            // }}
            handelSearchData={(data) => {
              handelSearchData(data);
            }}
            page={paginator.page}
            pageSize={paginator.sizePerPage}
            paginationRequired={true}
            // handleRemoveData={() => {
            //   handleRemoveData();
            // }}
            // handleSearchRemove={(data) => {
            //   handleSearchRemove(data)
            // }}
            getDataBasedOnPageNumber={getDataBasedOnPageNumber}
            striped
            hoverable
            searchQuery={searchQuery}
            totalNumberOfRecords={data.actionLogReducer.actionLogData.totalRecords}
          />
        </div>
      </div>
    </>
  );
};

export default ActionLogPage;

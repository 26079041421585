import _history from "./history";
import store from "../store";

const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userType");
  localStorage.clear();

  const state = store.getState();

  // console.log(state.tabReducer);

  Object.entries(state.tabReducer["openTabs"]).forEach(async (tab) => {
    await tab[1].window.close();
  });

  _history.push("/logout");
};

export default handleLogout;

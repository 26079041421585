import QLabel from "../../components/Forms/QLabel";
import CFSList from "../../components/CFSList";
import { HEADER_REPORTS_INSIGHT, Search_placeholder } from "../../utils/Labels";
import "./style.scss";
import { getReportsInsightData, getReportsInsightDataSearch } from "./actions";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH_MIN_CHAR, MAX_RECORDS_PER_PAGE } from '../../utils/Labels'
import QTextInput from "../../components/Forms/QTextInput";
import useDebounce from "../../utils/hooks/useDebounce";

const ReportsAndInsight = (props) => {
  const { ...otherprops } = props;

  const dispatch = useDispatch();

  const [searchData, setSearchearchData] = useState("");
  const [filterClear, setFilterClear] = useState(false);
  const [debouncedText]=useDebounce(searchData,750);

  const [reportsData, setReportsData] = useState({
    key: "reportsData",
    type: "REPORTS_DATA",
  });

  let data = useSelector((state) => {
    return state.reportsDataReducer;
  });

  useEffect(() => {
    if (debouncedText.length > SEARCH_MIN_CHAR) {
      dispatch(getReportsInsightDataSearch(reportsData.type, debouncedText, data.page, MAX_RECORDS_PER_PAGE));
      setFilterClear(false);
    } else if (filterClear && !debouncedText.length) {
      dispatch(getReportsInsightData(reportsData.type, data.page, MAX_RECORDS_PER_PAGE));
      setFilterClear(false);
    } else {
      setFilterClear(false);
    }
  }, [debouncedText])

  useEffect(() => {
    dispatch(getReportsInsightData(reportsData.type, 1, MAX_RECORDS_PER_PAGE));
  }, []);

  const handleSearch = (data) => {
    setSearchearchData(data);
  };
  const handleRemoveData = () => {
    dispatch(getReportsInsightData(reportsData.type, data.page, MAX_RECORDS_PER_PAGE));
    setSearchearchData("");
  };
  const handleSearchRemove = (data) => {
    if (data.keyCode === 8) {
      setFilterClear(true)
    }
  }
  const handelSearchData = (evt) => {
    let query = evt.target.value;
    setSearchearchData(query);
  };
  const fetchMoreData = () => {
    dispatch(getReportsInsightData(reportsData.type, data.page + 1, MAX_RECORDS_PER_PAGE));
  }
  return (
    <div className="d-flex  report-wrapper">
      <div className="col-12">
        <div className="header-wrapper">
          <div>
            <QLabel
              label={HEADER_REPORTS_INSIGHT}
              qlabelCls="audit-label"
            ></QLabel>
          </div>
          <div className="search-wrapper col-sm-6 col-md-4 p-0">
            {searchData && searchData.length ? "" : <div className="search-menu-report-icon"><img src="/images/Searchicon.svg" height="15px" width="15px" /></div>}
            <QTextInput
              className="search-bar report-search  fa fa-times"
              type="text"
              name="search"
              placeholder={Search_placeholder}
              onInputChange={handleSearch}
              handleSearchRemove={handleSearchRemove}
              onInputChange={handelSearchData}
              value={searchData}
            />
            {searchData && searchData.length > 0 && (
              <span className=" report-closeIcon">
                <img
                  src="images/close.svg"
                  className=""
                  alt=""
                  style={{
                    marginLeft: "-29px",
                    zIndex: "100",
                    height: "15px",
                    width: "15px",
                  }}
                  onClick={handleRemoveData}
                />
              </span>
            )}
          </div>
        </div>
        <CFSList
          qlistCls="cfs-list"
          data={data.reportsData}
          page={data.page}
          total_records={data.total_records}
          fetchMore={fetchMoreData}
        ></CFSList>
      </div>
    </div>
  );
};

export default ReportsAndInsight;

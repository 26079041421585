import ReactDOM from 'react-dom';
import React from "react";
import QToast from '../components/BaseUI/QToast/QToast';

export const showToast = (content,error) => {

    setTimeout(() => {
       return ReactDOM.unmountComponentAtNode(document.getElementById('show-toast'));
    }, 3000);

    return ReactDOM.render(
        <QToast  content={ <span className="toast-text">{content}</span> } imgCls="img-container" qtosCls={error?"error-toast":"toast"} imgURL={error?"images/error.png":"images/successtick.png"}/>,
      document.getElementById("show-toast")
    );
};
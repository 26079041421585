import { useState } from "react";
import Toast from 'react-bootstrap/Toast';
import './styles.scss';
const QToast = (props) => {
    let { imgURL = "", header = "", timeStamp = "", qtosCls = "", imgCls = "", content = "", autoHide = true, handelToast } = props;
    const [show, setShow] = useState(true);
    // console.log(show);
    return (
        <div className="qtoast-wrapper">
            <Toast onClose={() => {
                setShow(false);
                // handelToast(false)
            }} show={show} delay={3000} autohide={autoHide} >
                <Toast.Body className={qtosCls}>
                    {imgURL && <img src={imgURL} className={`rounded mr-2 ${imgCls}`} alt="" />}
                    {content}
                </Toast.Body>
            </Toast>
        </div>
    )
};
export default QToast;
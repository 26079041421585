export const PASSWORD_LABEL = "Password";
export const PASSWORD_PLACEHOLDER = "Enter Your Password";
export const LABEL_LOGOUT = "Log out";
export const SUCCESS = "success";
export const LOG_OUT_CONFIRMATION_TEXT = "Are you sure to signout from the application?"

// Reports & Insight
export const HEADER_REPORTS_INSIGHT = "Reports & Insight";
export const HEADER_HELP_AND_SUPPORT = "Help & Support";
export const SEARCH_MIN_CHAR = 2;
export const MAX_RECORDS_PER_PAGE = 20;

//URL Configuration

export const URL_configuration_Btn_Label="URL";

export const Search_placeholder="Search";
export const No_Data_Msg="Your search did not return any result.";
export const Delete_Pop_up_Msg="Are you sure you want to delete this item ?";
export const Delete_Successfull_Msg="URL deleted";
export const Add_New_URL_Msg="URL added";
export const Updated_URL_Msg="URL updated";
export const URL_Model_title="Configure URL";
// export const Supported_file_type_label="Supported file only CSV"


///group///
export const Group_Name_Field_Label="Group";
export const Group_Name_Field_Placeholder="Enter group name";
export const Group_Add_Model_Title="Add Group";
export const Group_Update_Model_Title="Edit Group";

export const Group_Description_Field_Label="Description";
export const Group_Description_Field_Placeholder="Enter description";

export const Group_Added_Btn_Label="Group";


export const User_Add_Model_Title="Add User";
export const User_Update_Model_Title="Edit User";
export const Supported_file_type_label="Bulk Import";
export const Browse_File="Browse";
import { ACTION_LOG } from "../../utils/EndPoints";
import makeRequest from "../../utils/request";
import { showToast } from "../../utils/showToast";

export const getActionLogs = ({ paginator, sort, query = "" }) => {
  return (dispatch) => {
    const url = `${ACTION_LOG}?q=${query}&page=${
      paginator.page
    }&numberOfRecords=${paginator.sizePerPage}${
      sort && sort.fieldName
        ? `&fieldName=${sort.fieldName}&order=${sort.order}`
        : ""
    }`;

    makeRequest(url, {
      method: "GET",
    })
      .then((res) => {
        dispatch({
          type: "ACTION_LOG",
          payload: res,
        });
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

import React,{useState} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
// import InfiniteScroll from "react-infinite-scroll-component";
import NoDataComp from '../NoDataComp'
import "./style.scss";
import { useDispatch } from "react-redux";
import { saveActionLog } from "../../utils/actionlog";

const CFSList = (props) => {
    let { horizontal = false, variant = "", data = [], action = true, qlistCls = "", fetchMore,total_records} = props;
    const dispatch = useDispatch();

    // console.log(data);

    const [_, setNewTab] = useState("")
    
    const saveTab = (tab) => {
        return (dispatch) => {
          dispatch({ type: "NEW_TAB", payload: tab });
        };
      };  
      
    const onhandleClick = async (e, item) => {
        let linkType = item.type === "1" ? "Audit" : "Report";
        const nw = await window.open(item.link, `${linkType}${item.id}` );
        await dispatch(saveTab(nw))
        await dispatch(saveActionLog(`Launched ${linkType}: ${item.description}`))

        setNewTab(e.target)
    }

    const fetchMoreData = () => {
        if (data.length < total_records) {
            fetchMore();
        }
    }
    return (
        <ListGroup horizontal={horizontal} as="ul" variant={variant} >
                {data && data.length ? data.map((item, index) => {
                    return (<a href="#" style={{textDecoration:"none",color:"#1d1d1d"}} onClick={(e) => onhandleClick(e, item)}>
                    <ListGroup.Item as="li" action={action} style={{borderRadius: "4px"}} variant={item.variant} active={item.active} disabled={item.disabled} >
                        {/* <QTooltip label={item.label} > */}
                        <div style={{ display: "flex",flexDirection:'column' }}>
                            <div className="list-label">
                                {/* {item.description}*/}
                                    {item.description}
                            </div>
                        </div>
                        {/* </QTooltip> */}
                    </ListGroup.Item>
                    </a>)
                })
                :data && data.length>=0? <NoDataComp label={<p>You have not as yet been assigned with any Audit Tools or Reports.<br/> Please contact your Team Manager or Quality Team for access to the correct tools.</p>} heading="You don't have anything assigned" />:""}

            </ListGroup>
    )
};
export default CFSList;
import { useRef,useEffect } from "react";
import { Supported_file_type_label,Browse_File } from "../../utils/Labels";

const CFSUploadFile = (props) => {
  const ref = useRef("");
  const { ID = "", accept = "", handleFileUpload,getFileUpload,uploadedFile="" } = props;

  return (
    <div className="file-upload-wrapper">
      <span className="file-text">
        <span ><span style={{ color: "red" }}>*</span>{Supported_file_type_label} </span>
      </span>
      <div className="file-container">
      <div className="inputfile-box">
        <input
          type="file"
          id="file"
          class="inputfile"
          accept={accept}
          ref={ref}
          onChange={getFileUpload}
        />
        <label for="file" className="d-flex">
          <span id="file-name" class="file-box">
            {uploadedFile?.name ?? "CSV file"}
          </span>
          <span className="file-button">
            {Browse_File}
          </span>
        </label>
      </div>
      <button className="upload-btn btn" disabled={!uploadedFile} onClick = {()=>handleFileUpload(ref)}>
      <i className="fa fa-upload" aria-hidden="true"></i>
      &nbsp;Upload
      </button>
      </div>
    </div>
  );
};

export default CFSUploadFile;

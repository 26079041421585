import { ALL_ROLES, URL_CONFIGURATION } from "../../utils/EndPoints";
import makeRequest from "../../utils/request";
import { showToast } from "../../utils/showToast";
import {
  Delete_Successfull_Msg,
  Add_New_URL_Msg,
  Updated_URL_Msg,
} from "../../utils/Labels";
import { saveActionLog } from "../../utils/actionlog";
const userType = JSON.parse(localStorage.getItem("userType"));

export const getUrlConfigData = ({
  paginator,
  sort,
  query = "",
  getAllRoles,
}) => {
  return (dispatch) => {
    makeRequest(
      `${URL_CONFIGURATION}?q=${query}&page=${paginator.page}&numberOfRecords=${
        paginator.sizePerPage
      }${
        sort && sort.fieldName
          ? `&fieldName=${sort.fieldName}&order=${sort.order}`
          : ""
      }`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        dispatch({
          type: "URL_CONFIG",
          payload:
            res.totalRecords > 0 ? (Array.isArray(res?.data) ? res : []) : [],
        });
        if (getAllRoles) {
          dispatch(getRoles());
        }
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};
export const getRoles = () => {
  return (dispatch) => {
    makeRequest(ALL_ROLES, {
      method: "GET",
    })
      .then((res) => {
        dispatch({
          type: "ROLES",
          payload: res,
        });
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

export const setNewUrlConfigData = ({ data, paginator, sort }) => {
  let params = {
    ...data,
  };
  params["createdById"] =
    userType && userType.userDetails && userType.userDetails.id;
  params["lastUpdatedById"] =
    userType && userType.userDetails && userType.userDetails.id;
  return (dispatch) => {
    makeRequest(URL_CONFIGURATION, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => {
        showToast(Add_New_URL_Msg);
        dispatch(getUrlConfigData({ paginator, sort }));
        dispatch(saveActionLog(`Added New URL: ${data.description}`));
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};
export const deleteUrlConfigData = ({ data, paginator, sort }) => {
  return (dispatch) => {
    makeRequest(`${URL_CONFIGURATION}/${data.id}`, {
      method: "DELETE",
    })
      .then((response) => {
        showToast(Delete_Successfull_Msg);
        dispatch(getUrlConfigData({ paginator, sort }));
        dispatch(saveActionLog(`Deleted URL: ${data.description}`));
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

export const editUrlConfigData = ({ data, paginator, sort }) => {
  let params = {
    ...data,
  };
  params["lastUpdatedById"] =
    userType && userType.userDetails && userType.userDetails.id;
  return (dispatch) => {
    makeRequest(`${URL_CONFIGURATION}/${data.id}`, {
      method: "PUT",
      body: JSON.stringify(params),
    })
      .then((response) => {
        showToast(Updated_URL_Msg);
        dispatch(getUrlConfigData({ paginator, sort }));
        dispatch(saveActionLog(`Edited URL: ${data.description}`));
      })
      .catch((error) => {
        showToast(error.message || "Error", true);
      });
  };
};

import { useState,useEffect } from "react";
import { Form, FormLabel } from "react-bootstrap";

const  QTextInput = ({qinputCls="", value="", label="", onInputChange, validationMsg="", handleSearchRemove,...otherProps}) => {
    const [updatedValue, setUpdatedValue] = useState(value);
    useEffect(() => {
     setUpdatedValue(value)
      // dispatch(getRole());
  }, [value])
    const onChange = (evt) => {
      setUpdatedValue(evt.target.value);
      if(onInputChange){
        onInputChange(evt)
      }
    }
   
    return (
      <div className="qtextinput-wrapper qtext-container ">
        {label && <FormLabel>{label}</FormLabel>}
        <Form.Control className={qinputCls} value={updatedValue} onChange={onChange} {...otherProps} onKeyDown={(eve)=>{
          if(handleSearchRemove && eve.keyCode === 8){
            handleSearchRemove(eve)
          }
        }}/>
        {/* <span><img src="images/close.png"/></span> */}
        <Form.Control.Feedback type="invalid">
          {validationMsg}
        </Form.Control.Feedback>
      </div>
    );
}
export default QTextInput;
import { REPORT_URL } from "../../utils/EndPoints";
import makeRequest from "../../utils/request";
import { showToast } from "../../utils/showToast";
export const getReportsInsightData = (type, page, numberOfRecords) => {
    let userID = JSON.parse(localStorage.getItem("userType"))?.userDetails?.id || 0;
    return dispatch => {
        makeRequest(`${REPORT_URL}/2/${userID}/?page=${page}&numberOfRecords=${numberOfRecords}`, {
            method: "GET",
        })
            .then((res) => {
                if (res.message) {
                    showToast(res.message, true)
                } else {
                    dispatch({
                        type: type,
                        payload: res.data,
                        total_records: res.totalNumberOfRecords,
                        page: page
                    });
                }
            })
            .catch((error) => {
                showToast(error.message || "Error", true)
            })
    }
};
export const getReportsInsightDataSearch = (type, query, page, numberOfRecords) => {
    let userID = JSON.parse(localStorage.getItem("userType"))?.userDetails?.id || 0;
    return dispatch => {
        makeRequest(`${REPORT_URL}/2/${userID}?q=${query}&page=${page}&numberOfRecords=${numberOfRecords}`, {
            method: "GET",
        })
            .then((res) => {
                dispatch({
                    type: "REPORTS_DATA_SEARCH",
                    payload: res.data,
                    total_records: res.totalNumberOfRecords,
                    page: 1
                });
            })
            .catch((error) => {
                showToast(error.message || "Error", true)
            })
    }
};

import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import './styles.scss';

const QAccordion = (props) => {
    let { data = [], defaultActiveKey = "0" } = props;
    const [activeId, setActiveId] = useState(defaultActiveKey);
    function toggleActive(id) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }
    return (
        <div className="qaccordion-wrapper">
            <Accordion defaultActiveKey={`${activeId}`}>
                {data && data.map((item, index) => {
                    return (<Card className={activeId === `${index}` ? "active" : ""}>
                        <Accordion.Toggle className="accordian-header" as={Card.Header} eventKey={`${index}`} onClick={() => toggleActive(`${index}`)}>
                            {item.question}
                            {activeId === `${index}` ? <i className="fa fa-minus"></i> : <i className="fa fa-plus"></i>}
                            
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`${index}`}>
                            <Card.Body>                                
                                <p dangerouslySetInnerHTML={{__html: item.answer}} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>)
                })}
            </Accordion>
        </div>
    )
};
export default QAccordion;
import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import "./App.scss";
import MainHeader from "./components/Layout/MainHeader";
import MainSidebar from "./components/Layout/MainSidebar";
import { Redirect, Route, Switch } from "react-router-dom";
import ConfigurationPage from "./pages/ConfigurationPage";
import ManagementPage from "./pages/ManagementPage";
import AuditSelectorPage from "./pages/AuditSelectorPage/index";
import ReportsAndInsight from "./pages/ReportsAndInsight";
import ActionLogPage from "./pages/ActionLogPage";
import CfsFooter from "./components/CfsFooter/CfsFooter";
import HelpSupportPage from "./pages/HelpSupportPage";
import { useDispatch, useSelector } from "react-redux";
import LandingPage from "./pages/LandingPage";
import { getUserLoginData } from "./auth/actions";
import { BASE_URL } from "./utils/EndPoints";
import { useIdleTimer } from "react-idle-timer";
import handleLogout from "./utils/handleLogout";
import ConfirmationModal from "./components/BaseUI/ConfirmationModal";
import axios from "axios";

let components = {
  ManagementPage: ManagementPage,
  ConfigurationPage: ConfigurationPage,
  AuditSelectorPage: AuditSelectorPage,
  ReportsAndInsight: ReportsAndInsight,
  HelpSupportPage: HelpSupportPage,
  ActionLogPage: ActionLogPage,
};

const menus = {
  admin: [
    {
      path: "/user-management",
      component: "ManagementPage",
    },
    {
      path: "/url-configuration",
      component: "ConfigurationPage",
    },
    {
      path: "/actionlog",
      component: "ActionLogPage",
    },
  ],
  customer: [
    {
      path: "/audit-selector",
      component: "AuditSelectorPage",
    },
    {
      path: "/reports-insight",
      component: "ReportsAndInsight",
    },
    {
      path: "/help-support",
      component: "HelpSupportPage",
    },
  ],
  adminNcustomer: [
    {
      path: "/audit-selector",
      component: "AuditSelectorPage",
    },
    {
      path: "/reports-insight",
      component: "ReportsAndInsight",
    },
    {
      path: "/help-support",
      component: "HelpSupportPage",
    },
    {
      path: "/user-management",
      component: "ManagementPage",
    },
    {
      path: "/url-configuration",
      component: "ConfigurationPage",
    },
    {
      path: "/actionlog",
      component: "ActionLogPage",
    },
  ],
};

function App() {
  const dispatch = useDispatch();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [menuType, setMenuType] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const timeout = 1000 * 60 * process.env.REACT_APP_SESSION_TIMEOUT_MIN; //minutes
  const promptTimeout = 1000 * process.env.REACT_APP_SESSION_TIMEOUT_MIN; //seconds
  const channel = new BroadcastChannel("AAPLUS");

  useEffect(() => {
    channel.onmessage = (ev) => {
      onActive();
    };

    return () => {
      channel.close();
    };
  }, [channel]);

  let isLoader = useSelector((state) => {
    return state.loginReducer.isLoader;
  });
  const toggleSideBarHandler = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  let userType = useSelector((state) => {
    return state.loginReducer.userRoleDetails;
  });
  useEffect(() => {
    if (userType) {
      if (userType?.userDetails?.isAdmin && userType?.userDetails?.isCustomer) {
        setMenuType("adminNcustomer");
      } else if (userType?.userDetails?.isAdmin) {
        setMenuType("admin");
      } else {
        setMenuType("customer");
      }
    }
  }, [userType]);

  useEffect(() => {
    fetch(`${BASE_URL}/whoami`)
      .then(async (res) => {
        // console.log(res, "@@@@@@");
        if (res.status === 200) {
          setIsAuthorized(true);
          let data = await res.json();
          // console.log(data.user);
          // console.log(data.user["nameID"]);
          // console.log(
          //   data.user[
          //     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
          //   ]
          // );
          dispatch(
            getUserLoginData(
              "login",
              "USERS",
              {
                userAdId: data.user["nameID"].toLocaleLowerCase(),
                firstName: data.user[
                  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
                ]
                  ? data.user[
                      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
                    ]
                  : "",
                lastName: data.user[
                  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"
                ]
                  ? data.user[
                      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"
                    ]
                  : "",
              },
              ""
            )
          );
        } else {
          setIsAuthorized(false);
        }
        // localStorage.setItem("isAuthenticated",true)
      })
      .catch((err) => {
        console.log(err);
        setIsAuthorized(false);
      });
  }, []);

  const onPrompt = () => {
    setShowLogoutModal(true);
  };

  const onIdle = () => {
    // Close Modal Prompt
    setShowLogoutModal(false);
    handleLogout();
  };

  const onActive = () => {
    // Close Modal Prompt
    setShowLogoutModal(false);
    reset();
  };

  const { reset } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt: onPrompt,
    onIdle: onIdle,
    onActive: onActive,
    crossTab: true,
    emitOnAllTabs: true,
    emitOnIdle: true,
  });

  const logoutHandler = async (e) => {
    console.log("logging out");
    const url = await axios.get("/api/logouturl");
    await window.location.replace(url.data.request);
    axios.get("api/logout");
    setIsAuthorized(false);
    return null;
  };

  return (
    <>
      {isAuthorized && showLogoutModal && (
        <ConfirmationModal
          body={`Click Confirm to Logout or Cancel to continue your session.`}
          title="Current session is about to end"
          show={showLogoutModal}
          fullscreen={false}
          onHide={() => onActive()}
          onConfirm={() => onIdle()}
        />
      )}

      <div className="App">
        {isLoader && (
          <div className="spinner-wrapper">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div id="show-toast" />
        <Container className="box-container" fluid>
          <Row className="full-height root-container">
            {isAuthorized ? (
              <>
                <MainSidebar
                  toggleSidebar={toggleSideBarHandler}
                  isCollapsed={isSidebarCollapsed}
                  role={userType?.userDetails}
                ></MainSidebar>
                <section className="main-section">
                  <MainHeader
                    toggleSidebar={toggleSideBarHandler}
                    user={`${
                      userType?.userDetails
                        ? userType?.userDetails?.firstName
                        : ""
                    } ${
                      userType?.userDetails
                        ? userType?.userDetails?.lastName
                        : ""
                    }`}
                    setIsAuthorized={setIsAuthorized}
                  ></MainHeader>
                  <Switch>
                    {userType?.userDetails?.isCustomer ? (
                      <Redirect exact from="/" to="/audit-selector" />
                    ) : userType?.userDetails?.isAdmin ? (
                      <Redirect exact from="/" to="/url-configuration" />
                    ) : (
                      <Redirect exact from="/landing-page" to="/" />
                    )}
                    {menuType &&
                      menus[menuType] &&
                      menus[menuType].map((item, index) => {
                        let Component = components[item.component];
                        return (
                          <Route path={item.path}>
                            <Component />
                          </Route>
                        );
                      })}
                  </Switch>
                  <CfsFooter />
                </section>
              </>
            ) : (
              <>
                {!isAuthorized && (
                  <Redirect exact from="/" to="/landing-page" />
                )}
                <Route exact path="/landing-page">
                  <LandingPage />
                </Route>
              </>
            )}
            <Route
              exact
              path="/logout"
              component={async (e) => logoutHandler(e)}
            />
          </Row>
        </Container>
      </div>
    </>
  );
}
export default App;
